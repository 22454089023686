/**
 * All Javascript for our project
 *
 * @package
 * @since 1.0
 * @version 1.0
 */

/**
 * jQuery
 *
 * @see {@link https://jquery.com/|jQuery}
 */
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

/* Widowfix - unorpah package */
import unorphan from 'unorphan';

/* Flowtype */
import flowtype from 'flowtype-js';

jQuery(function ($) {
  /**
   * **************************************************************************************
   *
   * GLOBAL FUNCTIONS
   * Instantiate these functions for all devices
   *
   * **************************************************************************************
   */

  /**
   * **************************************************************************************
   *
   * MOBILE AND TABLET FUNCTIONS
   * Instantiate these functions for mobile and tablet devices only
   *
   * **************************************************************************************
   */

  /**
   * BREAKPOINT SIZES
   * Must be the same as the sizes defined in style.scss
   */
  //const mobileSizeMin = '320px';
  //const mobileSizeMax = '767px';
  //const tabletSizeMin = '768px';
  //const tabletSizeMax = '959px';
  //const desktopSizeMin = '960px';
  //const desktopSizeMax = '1400px';

  /**
   * MEDIA QUERIES
   * Must be the same as the sizes defined in style.scss
   */
  //const mob = '(max-width: ' + mobileSizeMax + ')';
  //const mobTab = '(max-width: ' + tabletSizeMax + ')';
  //const tab = '(min-width: ' + tabletSizeMin + ') and (max-width: ' + tabletSizeMax + ')';
  //const tabDesk = '(min-width: ' + tabletSizeMin + ')';
  //const desk = '(min-width: ' + desktopSizeMin + ')';

  // testing
  /*function id_mq_test(mob, mobTab, tab, tabDesk, desk) {
	 	if (window.matchMedia(mob).matches) {
	 		console.log('MQ: Mobile');
	 	}
	 	if (window.matchMedia(mobTab).matches) {
	 		console.log('MQ: Mobile and Tablet');
	 	}
	 	if (window.matchMedia(tab).matches) {
	 		console.log('MQ: Tablet');
	 	}
	 	if (window.matchMedia(tabDesk).matches) {
	 		console.log('MQ: Tablet and Desktop');
	 	}
	 	if (window.matchMedia(desk).matches) {
	 		console.log('MQ: Desktop');
	 	}
	 }*/

  /**
   * STICKY HEADER
   */
  function idSticky() {
    const headerHeightSticky = $('.header').outerHeight();
    $('body').css('padding-top', headerHeightSticky);

    /*scroll = $( window ).scrollTop();

				// turn on sticky header when scrolling down the page
				if ( scroll ) {
					$('body').addClass('fixed');
				}

				// turn off sticky header when scrolled back to top
				else {
					$('body').removeClass('fixed');
				}*/
  }

  /**
   * OFF CANVAS NAV
   */
  function idNavOffcanvas() {
    // Create nav icon
    $('.nav-footer').prepend(
      '<a href="#menu" class="menu-link"><img src="/wp-content/themes/integrateddoorsets/img/icn-nav.svg"></a>'
    );

    // Init bigSlide
    $('.menu-link').bigSlide({
      menu: '.menu-main-container',
      side: 'right',
      easyClose: true,
      menuWidth: $('body').innerWidth() - 48 + 'px',
    });

    // Stop links being followed on parent links
    $('#menu-main .next').each(function () {
      $(this).attr('href', '#');
    });
  }

  /**
   * SLINKY MENU
   */
  function idNavSlinky() {
    // Create Slinky class
    $('.menu-main-container').addClass('slinky-menu');

    // Activate Slinky Menu
    $('.slinky-menu').slinky({
      label: true,
      speed: 300,
      resize: true,
      //theme: 'default'
    });
  }

  /**
   * MASONRY
   * See https://github.com/desandro/masonry
   */
  function idMasonry() {
    $('.grid-auto').masonry({
      itemSelector: 'li',
      columnWidth: 'li',
    });
  }

  /**
   * FLEXSLIDER
   * See https://www.woothemes.com/flexslider/
   */
  function idSlider() {
    $('.flexslider').flexslider({
      animation: 'slide',
      animationLoop: true,
      smoothHeight: false,
      slideshow: true,
      initDelay: 0,
      useCSS: true,

      pauseOnAction: true,
      pauseOnHover: true,
      touch: true,

      controlNav: false,
      directionNav: true,
      prevText: '',
      nextText: '',
    });
  }

  /**
   * MATCH HEIGHT
   * See https://github.com/liabru/jquery-match-height
   */
  function idEqualHeights() {
    // cta
    $('.cta .col').matchHeight();

    // accreditation icons
    //$( '.accreditation li' ).matchHeight();

    // galleries
    $('.gallery .images li').not('.gallery .images.grid-auto li').matchHeight();

    // masonary
    $('.excerpts .content h1').matchHeight();
  }

  /**
   * ACCORDION
   */

  /**
   * FILTER
   */
  function idAccordionFilter() {
    // hide form
    //$('.filter-panel').hide();

    // open close panel
    $('.filter-trigger').on('click', function () {
      $(this).toggleClass('open').toggleClass('close');
      $(this).next('.filter-panel').slideToggle();
    });
  }

  /**
   * SETUP THE ACCORDION
   */
  function idAccordionSetup() {
    // collapse all panels
    $('.acc-panel').hide();

    // copy headings to content panel
    $('.acc-header').each(function () {
      $(this)
        .children('h2')
        .clone()
        .prependTo($(this).next('.acc-panel').children('.content'));
    });
  }

  /**
   * ACTIVATION
   * This is where we make them work
   *
   * @param {Object} trigger
   */
  function idAccordion(trigger) {
    // accordion to open
    const targetAcc = $(trigger).parents('.acc-item');
    const targetPanel = $(targetAcc).find('.acc-panel');
    const targetIcon = $(targetAcc).find('.icon');
    const targetHeading = $(targetAcc).find('.acc-header h2');
    // const toBeScrolled =
    // 	$(trigger)
    // 		.closest('.accordions')
    // 		.find('.acc-item')
    // 		.find('.acc-panel')
    // 		.outerHeight() +
    // 	$(trigger)
    // 		.closest('.accordions')
    // 		.find('.acc-item')
    // 		.find('.acc-header')
    // 		.outerHeight();

    // if accordion clicked on is already open
    if ($(targetAcc).hasClass('acc-active')) {
      $(targetPanel).slideUp();
      $(targetAcc).removeClass('acc-active').addClass('acc-inactive');
      $(targetIcon).addClass('open').removeClass('close');
      $(targetHeading).fadeTo('fast', 1);
      return;
    }

    // non-active accordions
    const accAll = $('.acc-item');
    const accAllIcon = $(accAll).find('.icon');
    const accAllHeading = $(accAll).find('.acc-header h2');

    // close other accordions before opening a new one
    $('.acc-panel').slideUp();
    $('.acc-active').removeClass('acc-active').addClass('acc-inactive');
    $(accAllIcon).addClass('open').removeClass('close');
    $(accAllHeading).fadeTo('fast', 1);

    // setup the new accordion
    $(targetAcc).removeClass('acc-inactive').addClass('acc-active');
    $(targetIcon).addClass('close').removeClass('open');
    $(targetHeading).fadeTo('fast', 0);

    // get height of fixed header
    const headerHeightAcc = $('.header').outerHeight();

    // accordion to close
    // const openAccordionHeight = $(targetAcc)
    //   .prevAll(".acc-active")
    //   .children()
    //   .eq(1)
    //   .outerHeight();

    // set the scroll position

    // open the accordion
    $(targetPanel).slideDown(function () {
      const headerTopOffset = $(trigger).closest('.acc-header').offset().top;
      // scroll to the accordion before opening it
      $('html,body').animate(
        {
          scrollTop: headerTopOffset - headerHeightAcc,
        },
        200
      );
    });
  }

  /**
   * TRIGGER ACCORDION
   */

  // Trigger the accordion when clicking on the accordion header
  $('.acc-header .icon, .acc-header h2').on('click', function () {
    const trigger = $(this);
    idAccordion(trigger);
  });

  /**
   * SCROLL TO TARGET FROM URL HASH
   */

  /**
   * TRIGGER WHEN CLICKING ON A LINK
   * Don't apply to Leaderflush Shapland link in main nav
   * Don't apply to Effective Clear Opening Widths Calculator
   */
  $('.mega-sub-menu a, .sub-menu a')
    .not('#mega-menu-item-1927 a, #mega-menu-item-2793 a')
    .on('click touchstart', function (event) {
      const link = $(this).attr('href');
      const target = this.hash;

      // if the id exists on the current page
      if ($(target).length > 0) {
        event.preventDefault();

        // is it an accordion link?
        if (target.indexOf('-accordion-') >= 0) {
          const trigger = $(target).find('.acc-header h2');
          idAccordion(trigger);
        }

        // else it's a regular ol' scroll to link
        else {
          // get height of fixed header
          const headerHeightScroll = $('.header').outerHeight();

          // set the scroll position
          const scrollToPosition = $(target).offset().top - headerHeightScroll;

          // scroll to the accordion before opening it
          $('html,body').animate(
            {
              scrollTop: scrollToPosition,
            },
            500
          );
        }
      }

      // else the target is on another page so we should load that page first
      else {
        window.location.href = link;
      }
    });

  /**
   * TRIGGER ON PAGE LOAD
   */

  function accordionHandler(target) {
    if (target) {
      // is it an accordion link?
      if (target.indexOf('-accordion-') >= 0) {
        const trigger = $('#' + target).find('.acc-header h2');
        idAccordion(trigger);
      } else {
        // get height of fixed header
        const headerHeightScrollOnLoad = Math.ceil($('.header').outerHeight());
        //console.log(headerHeight);

        // set the scroll position
        const scrollToPosition = Math.ceil(
          $('#' + target).offset().top - headerHeightScrollOnLoad
        );
        //console.log(scrollToPosition);

        $('html, body').animate(
          {
            scrollTop: scrollToPosition,
          },
          500
        );
      }
    }
  }

  // store the hash (DON'T put this code inside the $() function, it has to be executed
  // right away before the browser can start scrolling!
  const targetWithHash = window.location.hash;
  const target = targetWithHash.replace('#', '');

  // delete hash so the page won't scroll to it
  window.location.hash = '';

  accordionHandler(target);

  // $(window).load(function () {
  //   accordionHandler(target);
  // });

  /**
   * BACK TO TOP
   */
  function idBackToTop() {
    $('#backtotop').on('click touchstart', function (event) {
      // stop browser following link
      event.preventDefault();

      // get the hash
      const targetBackToTop = this.hash;

      // scroll to top
      $('html, body').animate(
        {
          scrollTop: $(targetBackToTop).offset().top,
        },
        700,
        'swing',
        function () {
          window.location.hash = targetBackToTop;
        }
      );
    });
  }

  /**
   * WIDOW FIX
   * See https://matthewlein.com/widowfix/
   */
  function idWidowfix() {
    unorphan('.txt-bg, .excerpts .content h1, .cta h4', { br: true });
  }

  /**
   * FLOWTYPE
   * See https://github.com/simplefocus/FlowType.JS
   */
  function idFontResize() {
    flowtype(document.querySelector('html'), {
      fontRatio: 76.3,
      maxFont: 16,
      minFont: 10,
    });

    // all pages/sections
    flowtype(document.querySelector('body'), {
      minimum: 320,
      maximum: 959,
    });

    // off-canvas nav
    const menuContainer = document.querySelector('.menu-main-container');
    if (menuContainer) {
      flowtype(menuContainer, {
        maxFont: 12,
        minFont: 12,
      });
    }

    // contact
    const a = document.querySelector('.page-id-29 .fullwidth-content a');
    if (a) {
      flowtype(a, {
        maxFont: 45,
        minFont: 30,
      });
    }
  }

  /**
   * MAP
   * Hide the slogan image when interacting with the map
   */
  /*$('.map-quote').on('click touchstart', function() {
			$(this).fadeOut();
		});*/

  /**
   * **************************************************************************************
   *
   * LOAD THE FOLLOWING ON DOCUMENT LOAD
   *
   * **************************************************************************************
   */

  $(document).ready(function () {
    idSticky();
    idNavSlinky();
    idNavOffcanvas();
    //id_mq_test(mob, mobTab, tab, tabDesk, desk);
    idAccordionFilter();
    idAccordionSetup();
    idEqualHeights();
    idBackToTop();
    idWidowfix();
    idFontResize();
  });

  /**
   * **************************************************************************************
   *
   * LOAD THE FOLLOWING ON WINDOW LOAD
   *
   * **************************************************************************************
   */

  $(window).load(function () {
    idMasonry();
    idSlider();
  });

  /**
   * **************************************************************************************
   *
   * LOAD THE FOLLOWING ON WINDOW RESIZE
   *
   * **************************************************************************************
   */

  $(window).resize(function () {
    idSticky();
  });

  /**
   * **************************************************************************************
   *
   * LOAD THE FOLLOWING ON SCROLL
   *
   * **************************************************************************************
   */

  /*$(window).scroll( function() {
		});*/
});
