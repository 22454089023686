/**
 * Webpack entry point
 *
 * This is the starting point for Webpack to bundle all assets
 *
 * @package
 * @since 1.0
 * @version 1.0
 */

/* SCSS */
import './src/scss/screen.scss';

/* Flexslider */
import 'flexslider';

/* Match Height */
import 'jquery-match-height';

/* Masonry */
import 'masonry-layout/dist/masonry.pkgd';

/* Slinky */
import 'jquery-slinky';

/* Bigslide */
import 'bigslide/dist/bigSlide';

/* JS for all screen sizes */
import './src/js/app.js';

/* Inline images */
/* eslint-disable */
import iconNav from "./src/img/icn-nav.svg";
import iconTwitter from "./src/img/icon-twitter.png";
import iconTwitterRetina from "./src/img/icon-twitter@2x.png";
import iconLinkedin from "./src/img/icon-linkedin.png";
import iconLinkedinRetina from "./src/img/icon-linkedin@2x.png";
import logoAccreditation1 from "./src/img/logo-accreditation1.png";
import logoAccreditation1Retina from "./src/img/logo-accreditation1@2x.png";
import logoAccreditation2 from "./src/img/logo-accreditation2.png";
import logoAccreditation2Retina from "./src/img/logo-accreditation2@2x.png";
import logoAccreditation3 from "./src/img/logo-accreditation3.png";
import logoAccreditation3Retina from "./src/img/logo-accreditation3@2x.png";
import logoAccreditation4 from "./src/img/logo-accreditation4.png";
import logoAccreditation4Retina from "./src/img/logo-accreditation4@2x.png";
import logoAccreditation5 from "./src/img/logo-accreditation5.png";
import logoAccreditation5Retina from "./src/img/logo-accreditation5@2x.png";
import logoAccreditation6 from "./src/img/logo-accreditation6.png";
import logoAccreditation6Retina from "./src/img/logo-accreditation6@2x.png";
import logoAccreditation7 from "./src/img/logo-accreditation7.png";
import logoAccreditation7Retina from "./src/img/logo-accreditation7@2x.png";
/* eslint-enable */
